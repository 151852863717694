import Util from "@/utils/utils";
import { getList, getExport } from "./api";

export default {
    name: "",
    data() {
        return {
            breadcrumbData: [
                {
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/terminalManagement/trainingRecords",
                    name: "培训打卡记录",
                    title: "培训打卡记录"
                }
            ],
            hideOrShowSize: true,
            columns: [
                {
                    label: "培训主题",
                    prop: "title",
                    width:120,
                },
                {
                    label: "培训类别",
                    prop: "type",
                    width:120,
                },
                {
                    label: "培训人",
                    prop: "trainerName",
                    width:120,
                },
                // {
                //     label: "培训人数",
                //     prop: "totalNum",
                //     width:120,
                // },
                {
                    label: "签到时间",
                    prop: "beginTime",
                    width:120,
                },
                {
                    label: "签退时间",
                    prop: "endTime",
                    width:120,
                },
                {
                    label: "培训人数",
                    prop: "totalNum",
                    width:120,
                },
                {
                    label: "培训地址",
                    prop: "submitAddress",
                    width:150,
                },
                {
                    label: "照片",
                    slot: "btnClick",
                    ellipsis: true,
                    width:300,
                },
            ],
            tableData: [],
            pager: {
                pageNo: 1,
                pageSize: 10,
                count: 10
            },
            ssearchList: {
                startTimeFrom: '',// 开始时间
                startTimeTo: '',// 结束时间
                // type: '', // 任务类型
                title: '',// 任务主题
                userName: '',// 是否仅自己
                serviceCode: 'getTrainingRecordReportXtw'
            },
            searchNumber: 0,
            hideshow: false,
            isLoading: false,
            onluyOneMessage: false,
            imgURL: [],
            tableLoading: false
        };
    },
    watch: {
        "ssearchList.checkUserCode": function (val) {
            this.ssearchList.checkUserCode = val.trim();
        },
        "ssearchList.storeName": function (val) {
            this.ssearchList.storeName = val.trim();
        },
        "ssearchList.storeCode": function (val) {
            this.ssearchList.storeCode = val.trim();
        },
        "ssearchList.checkUserName": function (val) {
            this.ssearchList.checkUserName = val.trim();
        },
        "ssearchList.dutyUserCode": function (val) {
            this.ssearchList.dutyUserCode = val.trim();
        },
        "ssearchList.dutyUserName": function (val) {
            this.ssearchList.dutyUserName = val.trim();
        }
    },
    mounted() {
        // this.fun_date(-30);
        this.tablelist();
    },
    beforeCreate() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:#ffffff");
    },
    beforeDestroy() {
        document.querySelector("body").setAttribute("style", "");
    },
    methods: {
        cherkImg(item) {
            this.onluyOneMessage = true
            this.imgURL = item.split(',')
        },
        fun_date(num) {
            var date1 = new Date();
            var yuefen =
                date1.getMonth() + 1 < 10
                    ? "0" + Number(date1.getMonth() + 1)
                    : date1.getMonth();
            var ri =
                date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
            var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
            this.ssearchList.checkEnd = time1;

            var date2 = new Date(date1);
            date2.setDate(date1.getDate() + num);
            var yu =
                date2.getMonth() + 1 < 10
                    ? "0" + Number(date2.getMonth() + 1)
                    : date2.getMonth();
            var riq =
                date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
            var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
            this.ssearchList.checkStart = time2;
        },
        exportClick() {
            let data = this.ssearchList;
            data.serviceCode = 'exportTrainRecordXtw'
            this.isLoading = true;
            getExport(data)
                .then(res => {
                    this.isLoading = false;
                    Util.blobToJson(res.data)
                        .then(content => {
                            if (content && content.msg == "success") {
                                this.$message.success("下载成功，请到下载中心查看!");
                            }
                        })
                        .catch(err => {
                            Util.downloadFile(res.data, "培训打卡记录.xls");
                        })
                        .finally(() => {
                            this.expLoading = false;
                        });
                })
                .finally(res => {
                    this.isLoading = false;
                });
        },
        loadMore() {
            this.hideshow = !this.hideshow;
        },

        pageSearch(pager) {
            
            this.ssearchList.serviceCode = 'getTrainingRecordReportXtw'
            this.ssearchList.pageNo = pager.pageNo;
            this.ssearchList.pageSize = pager.pageSize;
            this.pager.pageNo = pager.pageNo
            this.pager.pageSize = pager.pageSize
            this.tablelist();
        },
        tablelist() {
            this.ssearchList.pageNo = this.pager.pageNo;
            this.ssearchList.pageSize = this.pager.pageSize;
            let data = this.ssearchList;
            this.tableLoading = true
            getList(data).then(res => {
                if (res.data.returnCode == 100) {
                    this.tableData = res.data.returnData.list;
                    if (res.data.returnData.list) {
                        this.searchNumber = res.data.returnData.totalCount;
                        this.pager.count = res.data.returnData.totalCount;
                        this.tableData.forEach(item => {
                            item.url = []
                            if (item.img1Url) {
                                item.url.push(item.img1Url)
                            }
                            if (item.img2Url) {
                                item.url.push(item.img2Url)
                            }
                            if (item.img3Url) {
                                item.url.push(item.img3Url)
                            }
                        })
                    }

                    this.tableLoading = false
                } else {
                    this.$message.warning(res.data.returnMsg || res.data.msg)
                    this.tableLoading = false
                }

            });
        },

        hideOrShow(data) {
            this.hideOrShowSize = data;
        },
        // 重置
        reset() {
            this.ssearchList = {
                startTimeFrom: '',// 开始时间
                startTimeTo: '',// 结束时间
                // type: '', // 任务类型
                title: '',// 任务主题
                userName: '',// 是否仅自己
                pageNo: 1,
                pageSize: 10,
                serviceCode: 'getTrainingRecordReportXtw'
            };
            this.tablelist();
        },

        // 分页
        pagesiezes(data, pageSize) {
            this.ssearchList.pageNo = data;
            this.ssearchList.pageSize = pageSize;
            this.tablelist();
        },
        handleChange(e) {
        },
        // 是否是品牌园
        handleChangePP(e) {
        },
        onChangecheckStart(e, time) {
            this.ssearchList.startTimeFrom = time;
        },
        onChangeEndTime(e, time) {
            this.ssearchList.startTimeTo = time;
        },
        searchClick() {
            this.tablelist();
        }
    }
};
